<script lang="ts" setup>
import {AvisMap, CardProps} from "../types/props";

const props = defineProps<{
  cardProps: CardProps
}>();
const avis = AvisMap.has(props.cardProps?.title) ?
    AvisMap.get(props.cardProps.title)
    : [];

const avisAverage = avis.map(avis => avis?.AvisStars)
    .reduce((a, b) => a + b, 0) / avis.length;
const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'long', day: 'numeric'};
  return new Date(dateString).toLocaleDateString('fr-FR', options);
};
</script>
<template>
  <div class=" w-11/12 max-w-5xl">
    <h3 class="font-bold text-xl mb-6 text-primary">Avis pour {{ cardProps.title }}</h3>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- Section Note Moyenne -->
      <div class="bg-white p-6 rounded-lg">
        <h4 class="font-semibold text-xl mb-4 text-primary">Note moyenne</h4>
        <div class="flex items-center space-x-4">
          <span class="text-4xl font-bold text-primary">{{ avisAverage.toFixed(1) }}</span>
          <div class="rating rating-md">
            <template v-for="i in 5" :key="i">
              <input
                  :checked="Math.round(avisAverage) === i"
                  :class="[
                                'mask mask-star-2',
                                i <= avisAverage ? 'bg-secondary' : 'bg-gray-300'
                            ]"
                  disabled
                  name="rating-avg"
                  type="radio"
              />
            </template>
          </div>
          <span class="text-sm text-gray-600">({{ avis.length }} avis)</span>
        </div>
      </div>

      <!-- Section Répartition des Notes -->
      <div class="bg-gray-100 p-6 rounded-lg">
        <h4 class="font-semibold text-xl mb-4 text-primary">Répartition des notes</h4>
        <div v-for="star in 5" :key="star" class="flex items-center space-x-3 mb-2">
          <span class="w-5 text-right font-bold text-sm">{{ star }}</span>
          <div class="w-full bg-gray-300 rounded-full h-3">
            <div
                :style="{width: `${(avis.filter(a => Math.round(a.AvisStars) === star).length / avis.length) * 100}%`}"
                class="bg-secondary h-3 rounded-full">
            </div>
          </div>
          <span class="w-10 text-sm">{{ avis.filter(a => Math.round(a.AvisStars) === star).length }}</span>
        </div>
      </div>
    </div>

    <!-- Section Avis Individuels -->
    <div class="mt-8 space-y-6">
      <div v-for="(avisItem, index) in avis" :key="index" class="bg-white p-6 rounded-lg ">
        <div class="flex justify-between items-center mb-3">
          <span class="font-semibold text-lg text-primary">{{ avisItem.AvisAuthor }}</span>
          <span class="text-sm text-gray-500">{{ formatDate(avisItem.AvisDate) || 'Date non disponible' }}</span>
        </div>
        <div class="rating rating-sm mb-3">
          <template v-for="i in 5" :key="i">
            <input
                :checked="Math.round(avisItem.AvisStars) === i"
                :class="[
                            'mask mask-star-2',
                            i <= avisItem.AvisStars ? 'bg-secondary' : 'bg-gray-300'
                        ]"
                disabled
                name="rating-{{ index }}"
                type="radio"
            />
          </template>
        </div>
        <p class="text-base leading-relaxed text-gray-700">{{ avisItem.AvisString }}</p>
      </div>
    </div>

    <div class="modal-action">
      <form method="dialog">
        <button class="btn btn-primary">Fermer</button>
      </form>
    </div>
  </div>

</template>
