<script lang="ts" setup>

import CardService from "../components/CardService.vue";
import CardAllService from "../components/CardAllService.vue";
import {useGeneralStore} from "../store/general-store";

const isolationcards = [
  {
    title: 'Isolation de toiture',
    description: 'Nous proposons des solutions d\'isolation pour les toitures, pour améliorer l\'efficacité énergétique de votre bâtiment.'
  },
  {
    title: 'Isolation sous toiture',
    description: 'Nous proposons des solutions d\'isolation pour les sous-toitures, pour améliorer l\'efficacité énergétique de votre bâtiment.'
  },
  {
    title: 'Isolation de toiture plate',
    description: 'Nous proposons des solutions d\'isolation pour les toitures plates, pour améliorer l\'efficacité énergétique de votre bâtiment.'
  },
];

const nettoyagecards = [
  {
    title: 'Nettoyage de toiture',
    description: 'Nous effectuons des nettoyages de toitures pour éliminer les déchets et les saletés qui peuvent endommager votre toiture.'
  },
  {
    title: 'Nettoyage de gouttière',
    description: 'Nous effectuons des nettoyages de gouttières pour éliminer les déchets et les saletés qui peuvent endommager votre système de drainage.'
  },
  {
    title: 'Démoussage de toiture',
    description: 'Nous effectuons des démoussages de toitures pour éliminer les mousses et les lichens qui peuvent endommager votre toiture.'
  },
];

const couvreurcards = [
  {
    title: 'Couvreur étancheur',
    description: 'Nous sommes spécialisés dans l\'étanchéité des toitures, pour éviter les fuites et les dégâts des eaux.'
  },
  {
    title: 'Couvreur ramoneur',
    description: 'Nous sommes spécialisés dans la maintenance et la réparation des cheminées et des conduits de fumée.'
  },
  {
    title: 'Couvreur bardeur',
    description: 'Nous sommes spécialisés dans la pose et la réparation des bardages pour les toitures.'
  }
];

const couverturecards = [
  {
    title: 'Couverture tuile plate',
    description: 'Nous proposons des solutions de couverture en tuile plate pour les toitures, pour une esthétique et une durabilité optimales.'
  },

  {
    title: 'Couverture de toit terrasse',
    description: 'Nous proposons des solutions de couverture pour les toits terrasses, pour une esthétique et une sécurité optimales.'
  },
  {
    title: 'Couverture en zinc',
    description: 'Nous proposons des solutions de couverture en zinc pour les toitures, pour une durabilité et une esthétique optimales.'
  },
  {
    title: 'Couverture tuile',
    description: 'Nous proposons des solutions de couverture en tuile pour les toitures, pour une esthétique et une durabilité optimales.'
  },
  {
    title: 'Couverture bac acier',
    description: 'Nous proposons des solutions de couverture en bac acier pour les toitures, pour une durabilité et une esthétique optimales.'
  },
  {
    title: 'Couverture de maison',
    description: 'Nous proposons des solutions de couverture pour les maisons, pour une esthétique et une durabilité optimales.'
  },
  {
    title: 'Couverture de terrasse',
    description: 'Nous proposons des solutions de couverture pour les terrasses, pour une esthétique et une sécurité optimales.'
  },
  {
    title: 'Toiture en pvc',
    description: 'Nous proposons des solutions de toiture en PVC pour les bâtiments, pour une durabilité et une esthétique optimales.'
  }
];
const reparationcards = [
  {
    title: 'Réparation de toiture',
    description: 'Nous effectuons des réparations de toitures pour remédier aux dégâts et aux fuites.'
  },
  {
    title: 'Rénovation de toiture en tuile',
    description: 'Nous proposons des solutions de rénovation pour les toitures en tuile, pour une esthétique et une durabilité optimales.'
  },
  {
    title: 'Rénovation de toiture',
    description: 'Nous proposons des solutions de rénovation pour les toitures, pour une esthétique et une durabilité optimales.'
  },
  {
    title: 'Remplacement de tuile',
    description: 'Nous effectuons des remplacements de tuiles pour les toitures, pour maintenir leur étanchéité et leur esthétique.'
  },
  {
    title: 'Changement de chéneau',
    description: 'Nous effectuons des changements de chéneaux pour les toitures, pour maintenir leur étanchéité et leur esthétique.'
  },
  {
    title: 'Dépannage couverture',
    description: 'Nous effectuons des dépannages de couverture pour remédier aux dégâts et aux fuites.'
  },
  {
    title: 'Dépannage toiture',
    description: 'Nous effectuons des dépannages de toiture pour remédier aux dégâts et aux fuites.'
  }
];

const terrassecards = [
  {
    title: 'Toiture terrasse',
    description: 'Nous proposons des solutions de toiture pour les terrasses, pour une esthétique et une sécurité optimales.'
  }
];
const zingueriecards = [
  {
    title: 'Zinguerie',
    description: 'Nous sommes spécialisés dans la fabrication et la pose de produits en zinc pour les toitures.'
  },
];
const maintenancecards = [
  {
    title: 'Maintenance de couverture',
    description: 'Nous proposons des solutions de maintenance pour les couvertures, pour prolonger leur durée de vie.'
  },
  {
    title: 'Réfection de couverture',
    description: 'Nous effectuons des réfections de couverture pour les toitures, pour maintenir leur étanchéité et leur esthétique.'
  },
  {
    title: 'Pose de gouttière',
    description: 'Nous effectuons des poses de gouttières pour les toitures, pour maintenir leur étanchéité et leur esthétique.'
  },
  {
    title: 'Châssis de toit',
    description: 'Nous effectuons des travaux de châssis de toit pour les toitures, pour maintenir leur étanchéité et leur esthétique.'
  },
  {
    title: 'Faîtage',
    description: 'Nous effectuons des travaux de faîtage pour les toitures, pour maintenir leur étanchéité et leur esthétique.'
  },
];

const fuitecards = [
  {
    title: 'Fuite de toiture',
    description: 'Nous effectuons des réparations de fuites de toiture pour éviter les dégâts des eaux.'
  },
  {
    title: 'Recherche de fuite de toiture',
    description: 'Nous effectuons des recherches de fuites de toiture pour identifier et réparer les dégâts des eaux.'
  },
];

const bardagecards = [

  {
    title: 'Bardage toiture',
    description: 'Nous effectuons des bardages de toitures pour protéger et embellir votre bâtiment.'
  },
];


const generalStore = useGeneralStore();
</script>

<template>
  <!-- <div id="app"></div> -->
  <!-- Hero Section -->
  <section class="flex items-center flex-col justify-between mb-6 md:mt-6">
    <div class="bg-white border-2 border-gray-300 mx-auto max-w-7xl mx-2">
      <div class="flex flex-col lg:flex-row-reverse">
        <!-- Image -->
        <div class="lg:w-1/2 p-8 pb-6 md:p-10">
          <img alt="travaux toiture image"
               class="w-full h-auto object-cover rounded-lg"
               src="/image/toiture.jpg"/>
        </div>
        <!-- Contenu texte et bouton -->
        <div class="lg:w-1/2 p-8 pb-0 pt-0 md:p-10 flex flex-col">
          <!-- Bouton en haut -->
          <button
              class="call-button w-full px-6 py-3 bg-gradient-to-r from-primary to-secondary text-white rounded-lg text-center font-bold text-lg hover:from-secondary hover:to-primary transition mb-4">
            Appelez Maintenant - Devis Gratuit
          </button>

          <!-- Séparateur DaisyUI -->
          <div class="divider my-4"></div>

          <!-- Contenu textuel -->
          <p class="text-3xl font-semibold text-secondary mb-4">Artisans Toiture du Sud avec 30 Ans d'Expertise</p>
          <p class="text-primary mb-6">Fort de 30 ans d'expérience dans la rénovation de toiture, Zanelly est votre
            partenaire de confiance pour tous vos projets de toiture. Notre équipe, passionnée par le métier, excelle
            dans la réparation, la rénovation et la construction neuve de toits, assurant des résultats de qualité qui
            résistent aux aléas du temps.</p>
          <p class="text-primary mb-6">Nous savons à quel point une toiture en bon état est cruciale pour protéger votre
            maison contre les intempéries et les variations climatiques. Un toit bien entretenu peut non seulement
            prolonger la durée de vie de votre propriété, mais aussi améliorer son efficacité énergétique en réduisant
            les pertes de chaleur et en prévenant les problèmes d'humidité. Nos experts sont là pour vous conseiller sur
            les meilleures pratiques d'entretien et pour vous proposer des solutions personnalisées adaptées à vos
            besoins spécifiques.</p>
          <p class="text-primary mb-6">En choisissant Zanelly, vous profitez d'une approche client sur mesure, avec des
            devis clairs et détaillés, une communication transparente tout au long du projet, et un engagement total
            envers votre satisfaction. Contactez-nous dès aujourd'hui pour discuter de votre projet de toiture et
            découvrez comment nous pouvons vous aider à atteindre vos objectifs avec professionnalisme et
            efficacité.</p>
        </div>
      </div>
    </div>
  </section>

  <section class="flex items-center min-h-screen flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-2">
      <CardService
          v-for="card in generalStore.getCardPropsList"
          :key="card.title"
          :cardProps="card"
      />
    </div>
    <h2 class="text-4xl font-bold text-secondary ">Tous nos autres services</h2>
  </section>


      <div class="divider divider-secondary text-3xl font-bold text-primary"> Isolation  </div>


  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">

      <CardAllService
          v-for="card in isolationcards"
          :title="card.title"
          :description="card.description"
      />

    </div>
  </section>

  <div class="divider divider-secondary text-3xl font-bold text-primary"> Nettoyage</div>

  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">
      <CardAllService
          v-for="card in nettoyagecards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>

  <div class="divider divider-secondary text-3xl font-bold text-primary">Couvreur</div>
  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">

      <CardAllService
          v-for="card in couvreurcards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>

  <div class="divider divider-secondary text-3xl font-bold text-primary">Couverture</div>

  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">
      <CardAllService
          v-for="card in couverturecards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>


  <div class="divider divider-secondary text-3xl font-bold text-primary">Réparation et Rénovation</div>

  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">

      <CardAllService
          v-for="card in reparationcards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>

  <div class="divider divider-secondary text-3xl font-bold text-primary">Terrasses</div>
  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">
      <CardAllService
          v-for="card in terrassecards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>

  <div class="divider divider-secondary text-3xl font-bold text-primary">Zinguerie</div>
  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">
      <CardAllService
          v-for="card in zingueriecards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>


  <div class="divider divider-secondary text-3xl font-bold text-primary">Maintenance</div>
  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">
      <CardAllService
          v-for="card in maintenancecards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>


  <div class="divider divider-secondary text-3xl font-bold text-primary">Fuites</div>
  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">
      <CardAllService
          v-for="card in fuitecards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>


  <div class="divider divider-secondary text-3xl font-bold text-primary">Bardage</div>
  <section class="flex items-center flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 p-2">
      <CardAllService
          v-for="card in bardagecards"
          :title="card.title"
          :description="card.description"
      />
    </div>
  </section>


</template>

<style scoped>

</style>
