// Enumération des data-testid utilisés dans les tests
export enum TestId {
    HEADER = 'header',
    FOOTER = 'footer',
    MAIN = 'main',
    MENU = 'menu',
    CONTACT_INFO = 'contact-info',
    PHONE = 'phone-number',
    SERVICES = 'services',
    SERVICES_PACKAGE = 'services-package',

    TOITURE_PAGELINK = '/toiture-page',
    RENOVATION_PAGELINK = '/renovation-page',
    TOITURE_RAVELEMENT_FACADELINK = '/toiture-ravalement-facade',
    TOITURE_NETTOYAGE_TOITURELINK = '/toiture-nettoyage-toiture',
    TOITURE_NETTOYAGE_DALLADES = '/toiture-nettoyage-facade',
    SERVICE_NETTOYAGE_GOUTIERE_LINK = '/service-nettoyage-goutiere',
    SERVICE_REMPLACEMENT_TUILES_TOITURE_LINK = '/service-remplacement-tuiles-toiture',
    SERVICE_MACONNERIE_TOITURE_LINK = '/service-maçonnerie-toiture',
    SERVICE_PEINTURE_TOITURELINK = '/service-peinture-toiture',
    SERVICE_ISOLATION_TOITURELINK = '/service-isolation-toiture',
    SERVICE_REPARATION_TOITURELINK = '/service-reparation-toiture',
    SERVICE_NETTOYAGE_GOUTIERELINK = '/service-nettoyage-goutiere',
    SERVICE_CREPI_TOITURE_LINK = '/service-crepi-toiture',
    SERVICE_DEPANNAGE_TOITURE_LINK = '/service-depannage-toiture',

    TOITURE_PAGE_CONTENT = 'toiture-page-content',
    RENOVATION_PAGE_CONTENT = 'renovation-page-content',
    TOITURE_RAVELEMENT_FACADE_CONTENT = 'toiture-ravalement-facade-page-content',
    TOITURE_NETTOYAGE_FACADE_CONTENT = 'toiture-nettoyage-facade-page-content',
    TOITURE_NETTOYAGE_TOITURE_CONTENT = 'toiture-nettoyage-toiture-page-content',
    SERVICE_PEINTURE_TOITURE_CONTENT = 'service-peinture-toiture-page-content',
    SERVICE_ISOLATION_TOITURE_CONTENT = 'service-isolation-toiture-page-content',
    SERVICE_REPARATION_TOITURE_CONTENT = 'service-reparation-toiture-page-content',
    SERVICE_NETTOYAGE_GOUTIERE_CONTENT = 'service-nettoyage-goutiere-content',
    SERVICE_REMPLACEMENT_TUILES_TOITURE_CONTENT = 'service-remplacement-tuiles-toiture-content',
    SERVICE_CREPI_TOITURE_CONTENT = 'service-crepi-content',
    SERVICE_DEPANNAGE_TOITURE_CONTENT = 'service-depannage-toiture-content',
    SERVICE_MACONNERIE_TOITURE_CONTENT = 'service-maconnerie-toiture-content',

    CALCULATE_DEVIS_BUTTON = 'calculate-devis-button',
    DEVIS_FORM = 'devis-form',
    DEVIS_SURFACE_INPUT = 'devis-surface-input',
    DEVIS_SURFACE_RESULT = 'devis-price-result',

    DEVIS_SURFACE_CONTACT_PHONE = 'devis-contact-client-phone',
    DEVIS_SURFACE_CONTACT_EMAIL = 'devis-contact-client-email',
    DEVIS_SURFACE_CONTACT_CLIENT_RECONTACT_ME_BUTTON = 'devis-contact-client-recontact-me-button',
    CONFIRMATION_MESSAGE = 'confirmation-message',

    DEVIS_SURFACE_INSTANT_CONTACT_PHONE_VENDOR = 'devis-contact-vendor-phone',
    DEVIS_SURFACE_INSTANT_CONTACT_EMAIL_VENDOR = 'devis-contact-vendor-email',
}