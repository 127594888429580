<script lang="ts" setup>
import {defineProps} from 'vue';
import {AvisMap, CardProps} from "../types/props";
import ContentAvis from "./ContentAvis.vue";
import {useRouter} from "vue-router";
import {useGeneralStore} from "../store/general-store";

const props = defineProps<{
  cardProps: CardProps
}>();


const avis = AvisMap.has(props.cardProps.title) ?
    AvisMap.get(props.cardProps.title)
    : [];

const avisAverage = avis.map(avis => avis?.AvisStars)
    .reduce((a, b) => a + b, 0) / avis.length;

const avisPopupId = props.cardProps.title.toString() + '-popup';

function toggleAvisPopup(event: Event) {
  event.stopPropagation();
  const dialog = document.getElementById(avisPopupId);
  if (dialog) {
    dialog.showModal();
  }
}

const router = useRouter();
const generalStore = useGeneralStore();

function navigateToServiceSpecificPage() {
  generalStore.setPageDataService(props.cardProps);
  router.push({
    path: props.cardProps.link,
  })
}
</script>

<template>
  <div
      class="w-full max-w-sm rounded-sm bg-white relative flex flex-col h-full border-2 border-gray-300"
      style="cursor: pointer"
      :data-testid="cardProps.link"
      @click="navigateToServiceSpecificPage">
      <div class="w-full h-72 overflow-hidden p-8">
        <img :alt="cardProps.imageAlt"
             :src="props.cardProps.imageSrc"
             class="w-full h-full object-cover"/>
      </div>
    <div class="px-5 pb-5 flex flex-col flex-grow">
      <a :href="cardProps.link" class="flex-grow">
        <h5 class="text-2xl font-semibold tracking-tight text-secondary text-center">
          {{ cardProps.title }}
        </h5>
        <p class="text-primary mt-2 text-center">
          {{ cardProps.description }}
        </p>
      </a>
      <div class="flex items-center justify-center mt-8 mb-4">

        <div v-if="avis.length" class="flex items-center space-x-1"
             @click="toggleAvisPopup"
        >
          <span class="bg-yellow-100 text-yellow-800 text-xs font-semibold px-2.5 py-0.5 rounded ml-3">
            {{ avisAverage }}
          </span>
          <div class="rating rating-md rating-half">

            <template v-for="i in 10" :key="i">
              <input
                  :checked="Math.ceil(avisAverage * 2) === i"
                  :class="[
              'mask mask-star-2',
              i % 2 ? 'mask-half-1' : 'mask-half-2',
              i <= avisAverage * 2 ? 'bg-secondary' : 'bg-gray-300'
            ]"
                  name="rating-10"
                  type="radio"
              />
            </template>
          </div>
        </div>

      </div>
      <div class="mt-auto">
        <div class="mt-6">
          <a :href="cardProps.link"
             class="block px-6 py-3 bg-primary text-white rounded-lg text-center font-bold text-lg hover:from-secondary hover:to-primary transition">
            En savoir plus
          </a>
        </div>
      </div>
    </div>
  </div>
  <dialog :id="avisPopupId" class="modal text-primary">
    <ContentAvis :card-props="props.cardProps"/>
  </dialog>
</template>

