<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    title: String,
    description: String,
    link: String,
    price: String
});

</script>

<template>

                <div class="card bg-base-200 shadow-md">
                <div class="card-body">
                    <h3 class="card-title text-2xl text-secondary">
                        {{ title }}

                    </h3>
<p>
    {{ description }}
</p>
                    <div class="mt-6">
                        <span class="text-4xl font-bold text-accent text-secondary ">
                                                {{ price }}


                        </span>
                        <span class="text-base-content">/ intervention</span>
                    </div>
                    <a href="services.html" class="btn btn-accent btn-outline mt-8">Plus d'informations</a>
                    <a href="#contact" class="btn btn-primary mt-8">Obtenir un devis</a>
                </div>
            </div>

</template>

<style scoped></style>
