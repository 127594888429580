<script lang="ts" setup>
import {cardsMapByLink} from "../../store/general-store";
import {AvisMap} from "../../types/props";
import ContentAvis from "../../components/ContentAvis.vue";
import {useRoute} from "vue-router";
import {TestId} from "../../types/anchor";

const route = useRoute();
console.log(route.path);
if (cardsMapByLink.hasOwnProperty(route.path as TestId) === false) {
  throw new Error(`No card data found for ${route.path}`);
}
const cardProps = cardsMapByLink[route.path];
console.log(cardProps);

const avis = AvisMap.has(cardProps.title) ?
    AvisMap.get(cardProps.title)
    : [];
</script>

<template>
  <div class="bg-base-100 min-h-screen">
    <div class="container mx-auto px-4 max-w-[1200px]">
      <h1 class="text-4xl font-bold tracking-tight text-secondary text-center mb-8">
        Service de Dépannage Rapide avec Camions Nacelle - Devis Gratuit en 24h !
      </h1>

      <div class="bg-white rounded-lg mb-8 overflow-hidden">
        <div class="flex flex-col lg:flex-row-reverse items-center">
          <div class="lg:w-1/2 p-8">
            <img alt="Camion nacelle en intervention"
                 class="w-full h-full object-cover rounded-lg"
                 src="https://www.hellopro.fr/images/produit-2/1/9/4/nacelle-articulee-24d-speed-h-h-camion-nacelle-8084491.jpg"/>
          </div>
          <div class="lg:w-1/2 p-8">
            <h2 class="text-3xl font-semibold text-secondary mb-4">Interventions Rapides et Efficaces</h2>
            <p class="text-primary mb-6">Notre équipe utilise une flotte de camions nacelle sur véhicules légers (VL)
              pour réaliser des interventions de dépannage rapides et efficaces, offrant ainsi des solutions sûres et
              innovantes pour résoudre les problèmes les plus complexes.</p>
            <button
                class="call-button w-full px-6 py-3 bg-gradient-to-r from-primary to-secondary text-white rounded-lg text-center font-bold text-lg hover:from-secondary hover:to-primary transition">
              Appelez Maintenant - Devis Gratuit
            </button>
          </div>
        </div>
      </div>

      <div class="grid md:grid-cols-2 gap-8 mb-8">
        <section class="bg-white p-6 rounded-lg">
          <h2 class="text-2xl font-semibold text-secondary mb-4">Avantages de nos Camions Nacelle</h2>
          <ul class="list-disc list-inside text-primary">
            <li>Accès rapide aux zones difficiles</li>
            <li>Interventions en hauteur sécurisées</li>
            <li>Flexibilité pour divers types de dépannages</li>
            <li>Réduction des temps d'intervention</li>
          </ul>
        </section>

        <section class="bg-white p-6 rounded-lg">
          <h2 class="text-2xl font-semibold text-secondary mb-4">Nos Services de Dépannage</h2>
          <ul class="list-disc list-inside text-primary">
            <li>Réparations en hauteur</li>
            <li>Maintenance préventive</li>
            <li>Interventions d'urgence 24/7</li>
            <li>Solutions sur mesure pour problèmes complexes</li>
          </ul>
        </section>
      </div>

      <div class="bg-white rounded-lg mb-8 p-6">
        <ContentAvis v-if="avis.length" :card-props="cardProps"/>
      </div>

      <section class="text-center bg-blue-600 p-8 rounded-lg">
        <h2 class="text-3xl font-bold text-white mb-4">Besoin d'une Intervention Rapide ?</h2>
        <p class="text-white text-lg mb-6">Obtenez votre devis gratuit en 24h et bénéficiez de notre expertise en
          dépannage !</p>
        <a href="tel:0661779814">
          <button
              class="px-8 py-4 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-lg text-center font-bold text-xl hover:from-green-500 hover:to-blue-600 transition animate-bounce">
            Appelez le 06 61 77 98 14
          </button>
        </a>
        <p class="text-white mt-4">Ou envoyez-nous un message pour être rappelé dans l'heure</p>
      </section>
    </div>
  </div>
</template>

<style scoped>
</style>