<script setup>
import {defineProps} from 'vue';

const props = defineProps({
  title: String,
  description: String,
});

</script>

<template>

  <div class="w-full max-w-sm rounded-sm bg-white relative border-gray-300 flex flex-col h-full border-2">
    <div class="px-5 py-8 flex flex-col flex-grow">
      <h1 class="text-3xl font-semibold tracking-tight text-secondary text-center mb-4">
        {{ title }}
      </h1>
      <p class="text-primary text-center mb-8">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<style scoped></style>
