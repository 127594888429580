export enum ServiceName {
    AllTravauxToiture = 'Travaux sur tout type de toiture',
    Peinture = 'Peinture intérieure/extérieure',
    Facade = 'Ravalement de Façade',
    NettoyageTerrasse = 'Nettoyage de toitures, terrasses',
    NettoyageFacade = 'Nettoyage de façades, dallades, murettes',
    Maconnerie = 'Maçonnerie',
    RemplacementTuiles = 'Remplacement de tuiles',
    Crepi = 'Crépi',
    Depannage = 'Dépannage'
}

export interface InterfaceAvis {
    AvisAuthor: string;
    AvisString: string;
    AvisStars: number;
    AvisDate: string;
}


export interface CardProps {
    title: ServiceName;
    description: string;
    imageSrc: string;
    imageAlt: string;
    link: string;
    pageTestId: string
}


export const AvisMap = new Map<ServiceName, InterfaceAvis[]>();
AvisMap.set(
    ServiceName.AllTravauxToiture,
    [
        {
        AvisAuthor: "Christian",
        AvisString: "J'ai fait appel plusieurs fois à cette excellente entreprise de couverture pour des travaux de peinture et de rénovation. Les travaux ont été très bien faits et dans les délais. C'est pourquoi je la recommande.",
        AvisStars: 5,
        AvisDate: "7 juin 2024"
    },
    {
        AvisAuthor: "RaphaëlDrommelschlag",
        AvisString: "Sérieux, ponctualité et très haute compétence pour cette entreprise familiale très sympathique. Cet artisan a la rare particularité d'expliquer avant, pendant et après. Tous les atouts sont réunis pour être rassuré et satisfait totalement. Je recommande",
        AvisStars: 5,
        AvisDate: "6 avril 2022"
    },
    {
        AvisAuthor: "TaylorTaylor",
        AvisString: "Entreprise très réactif travail propre et soigné.",
        AvisStars: 5,
        AvisDate: "29 Janvier 2022"
    },
    {
        AvisAuthor: "SandraBaptiste",
        AvisString: "Très bon travail très soigneux, le travail a était réalisé très sérieusement et dans les temps .",
        AvisStars: 5,
        AvisDate: "21 Octobre 2021"
    }

    ])



AvisMap.set(
    ServiceName.Peinture,
    [
        {
        AvisAuthor: "Koko",
        AvisString: "CETTE ENTREPRISE ( LE PAPA ET LE FILS ) EST INTERVENUE DANS DES TRAVAUX DE PEINTURE EXTERIEURE (VOLETS FENETRES PORTES FENETRES ) .TRAVAIL BIEN FAIT .LE PAPA VEILLE ET DONNE CONSEIL A SON FILS. BINOME QUI FONCTIONNE BIEN . SATISFAITE DU TAVAIL.",
        AvisStars: 4,
        AvisDate: "10 Novembre 2022"
    }])
AvisMap.set(
    ServiceName.NettoyageFacade,
    [
        {
        AvisAuthor: "Laga31",
        AvisString: "Très satisfait d’une prestation de nettoyage de façade. Travail bien réalisé. Façade comme neuve. Nous recommandons cette entreprise.",
        AvisStars: 5,
        AvisDate: "10 mai 2022"
    }])

AvisMap.set(
    ServiceName.Maconnerie,
    [
        {
        AvisAuthor: "Michel Savoldelli",
        AvisString: "Entreprise rapide ,efficace , chantier rendu propre. Labège",
        AvisStars: 5,
        AvisDate: "22 novembre 2021"
    }])

AvisMap.set(
    ServiceName.Depannage,
    [
        {
        AvisAuthor: "Espérance",
        AvisString: "Intervention rapide (très appréciable quand une bonne pluie aggraverait les dégâts), très bonne prestation, bon rapport qualité prix. Jeune homme très consciencieux.",
        AvisStars: 5,
        AvisDate: "18 juin 2022"
    }])
