<script setup lang="ts">
import {ref} from 'vue';

interface Link {
  name: string;
  url: string;
}

const links: Link[] = [
  {name: 'Services', url: '/'},
  {name: 'Tarifs', url: '/tarifs'},
  {name: 'Contact', url: '/contact'},
];

const isMenuOpen = ref(false);
</script>

<template>
  <!-- Navbar -->
  <div class="fixed top-0 left-0 right-0 z-50 bg-white p-4 sm:p-5 border-2 border-gray-300" data-testid="header">
    <div class="container mx-auto flex flex-row items-center justify-between">
      <div class="dropdown self-start sm:self-center" data-testid="menu">
        <div class="btn btn-secondary bg-secondary text-white" role="button" tabindex="0">
          <svg
              class="h-5 w-5 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 6h16M4 12h16M4 18h7"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"/>
          </svg>
        </div>
        <ul
            class="menu menu-sm dropdown-content bg-white rounded-box z-[1] mt-5 w-52 p-2 shadow text-white"
            tabindex="0">
          <li v-for="link in links" :key="link.name" class="">
            <a :href="link.url" class="menu-title text-primary">{{ link.name }}</a>
          </li>
        </ul>
      </div>

      <div class="flex flex-row space-x-2">
        <a class="btn sm:btn-wide rounded-md  px-6 py-3 bg-gradient-to-r bg-secondary text-white" data-testid="phone-number"
           href="tel:0661779814">
          <svg
              class="h-5 w-5 sm:mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"/>
          </svg>
          <span class="">
            0661779814
          </span>
        </a>
        <a class="btn sm:btn-wide rounded-md  px-6 py-3 bg-secondary  text-white" data-testid="email"
           href="mailto:zanellidavid31@gmail.com">
          <svg
              class="h-5 w-5 sm:mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"/>
          </svg>
          <span class="hidden sm:inline">Mail</span>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Custom styles can be added here if needed */
</style>
