<script lang="ts" setup>
import {cardsMapByLink} from "../../store/general-store";
import {AvisMap} from "../../types/props";
import ContentAvis from "../../components/ContentAvis.vue";
import {useRoute} from "vue-router";
import {TestId} from "../../types/anchor";

const route = useRoute();
console.log(route.path);
if (cardsMapByLink.hasOwnProperty(route.path as TestId) === false) {
  throw new Error(`No card data found for ${route.path}`);
}
const cardProps = cardsMapByLink[route.path];
console.log(cardProps);

const avis = AvisMap.has(cardProps.title) ?
    AvisMap.get(cardProps.title)
    : [];
</script>

<template>
  <div :data-testid="cardProps.pageTestId" class="bg-base-100 min-h-screen">
    <div class="container mx-auto px-4 max-w-[1200px]">

      <section class="bg-white rounded-lg mb-8 mt-6 shadow-lg overflow-hidden">
        <div class="flex flex-col-reverse lg:flex-row">
          <div class="w-full lg:w-1/2 p-4 lg:p-8">
            <img alt="travaux toiture image"
                 class="w-full h-auto object-cover rounded-lg"
                 src="/image/toiture.jpg"/>
          </div>
          <div class="w-full lg:w-1/2 p-4 lg:p-8">
            <h2 class="text-2xl lg:text-3xl font-semibold text-secondary mb-4">Experts en Travaux de Toiture avec 30 Ans
              d'Expérience</h2>
            <p class="text-sm lg:text-base text-primary mb-4">Avec 30 ans d'expérience dans la rénovation de toiture,
              Zanelly est votre partenaire de confiance pour tous vos besoins en toiture. Notre équipe possède une
              expertise approfondie dans la réparation, la rénovation et la construction neuve de toits, garantissant
              des résultats de qualité supérieure qui résistent à l'épreuve du temps.</p>
            <p class="text-sm lg:text-base text-primary mb-4">Nous nous engageons à utiliser des matériaux de premier
              choix et des techniques innovantes pour assurer la durabilité et la performance de chaque projet. Que vous
              ayez besoin de réparer des fuites, de moderniser l'apparence de votre toit, ou d'entreprendre un projet
              complet de construction neuve, notre savoir-faire éprouvé vous garantit un travail bien fait.</p>
            <p class="text-sm lg:text-base text-primary mb-4">Faites confiance à notre longue expérience et bénéficiez
              de conseils experts pour la réalisation de votre projet. Contactez-nous dès aujourd'hui pour obtenir un
              devis gratuit et personnalisé. Nous sommes là pour vous accompagner à chaque étape et répondre à toutes
              vos questions.</p>
            <button
                class="w-full px-6 py-3 bg-gradient-to-r from-primary to-secondary text-white rounded-lg text-center font-bold text-lg hover:from-secondary hover:to-primary transition">
              Appelez Maintenant - Devis Gratuit
            </button>
          </div>
        </div>
      </section>


      <div class="grid md:grid-cols-2 gap-8 mb-8">
        <section class="bg-white p-6 rounded-lg">
          <h2 class="text-2xl font-semibold text-secondary mb-4">Nos Services de Toiture</h2>
          <ul class="list-disc list-inside text-primary">
            <li>Réparation de fuites et de dégâts</li>
            <li>Remplacement de tuiles ou d'ardoises</li>
            <li>Rénovation complète de toiture</li>
            <li>Installation de toiture pour construction neuve</li>
            <li>Pose de fenêtres de toit et de gouttières</li>
          </ul>
        </section>

        <section class="bg-white p-6 rounded-lg">
          <h2 class="text-2xl font-semibold text-secondary mb-4">Pourquoi Nous Choisir ?</h2>
          <ul class="list-disc list-inside text-primary">
            <li>Expertise pour tous types de toits</li>
            <li>Utilisation de matériaux de haute qualité</li>
            <li>Équipe de professionnels qualifiés</li>
            <li>Respect des normes de sécurité</li>
            <li>Garantie sur nos travaux</li>
          </ul>
        </section>
      </div>

      <div class="bg-white rounded-lg mb-8 p-6">
        <ContentAvis v-if="avis.length" :card-props="cardProps"/>
      </div>

      <section class="text-center bg-blue-600 p-8 rounded-lg">
        <h2 class="text-3xl font-bold text-white mb-4">Votre Toiture Mérite le Meilleur !</h2>
        <p class="text-white text-lg mb-6">Obtenez votre devis gratuit en 24h et bénéficiez de notre expertise en
          travaux de toiture !</p>
        <a href="tel:0661779814">
          <button
              class="px-8 py-4 bg-gradient-to-r from-green-400 to-blue-500 text-white rounded-lg text-center font-bold text-xl hover:from-green-500 hover:to-blue-600 transition animate-bounce">
            Appelez le 06 61 77 98 14
          </button>
        </a>
        <p class="text-white mt-4">Ou envoyez-nous un message pour être rappelé dans l'heure</p>
      </section>
    </div>
  </div>
</template>

<style scoped>
</style>