import {createApp} from 'vue'
import './style.css'
import App from './App.vue'
import {createRouter, createWebHistory} from "vue-router";
import Services from "./pages/Services.vue";
import Tarifs from "./pages/Tarifs.vue";
import NettoyageToiture from "./pages/services/ToiturePage.vue";
import {TestId} from "./types/anchor";
import {createPinia} from "pinia";
import ServicePeinture from "./pages/services/ServicePeinture.vue";
import RavalementFacade from "./pages/services/RavalementFacade.vue";
import NettoyageToitureTerrasse from "./pages/services/NettoyageToitureTerrasse.vue";
import NettoyageFacadeDallades from "./pages/services/NettoyageFacadeDallades.vue";
import MaconneriePage from "./pages/services/MaconneriePage.vue";
import TuilePage from "./pages/services/TuilePage.vue";
import Crepi from "./pages/services/Crepi.vue";
import Depannage from "./pages/services/Depannage.vue";


const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'services',
            component: Services
        },
        {
            path: '/tarifs',
            name: 'tarifs',
            component: Tarifs
        },
        {
            path: TestId.TOITURE_PAGELINK,
            name: 'toiture-page',
            component: NettoyageToiture,
        },
        {
            path: TestId.SERVICE_PEINTURE_TOITURELINK,
            name: 'service-peinture-toiture',
            component: ServicePeinture,
        },
        {
            path: TestId.TOITURE_RAVELEMENT_FACADELINK,
            name: 'toiture-ravalement-facade',
            component: RavalementFacade,
        },


        {
            path: TestId.TOITURE_NETTOYAGE_TOITURELINK,
            name: 'toiture-nettoyage-terrasse',
            component: NettoyageToitureTerrasse,
        },

        {
            path: TestId.TOITURE_NETTOYAGE_DALLADES,
            name: 'toiture-nettoyage-dallades',
            component: NettoyageFacadeDallades,
        },

        {
            path: TestId.SERVICE_MACONNERIE_TOITURE_LINK,
            name: 'service-maconnerie-toiture',
            component: MaconneriePage,
        },
        {
            path: TestId.SERVICE_REMPLACEMENT_TUILES_TOITURE_LINK,
            name: 'service-remplacement-tuiles-toiture',
            component: TuilePage,
        },
        {
            path: TestId.SERVICE_CREPI_TOITURE_LINK,
            name: 'service-crepi-toiture',
            component: Crepi
        },
        {
            path: TestId.SERVICE_DEPANNAGE_TOITURE_LINK,
            name: 'service-depannage-toiture',
            component: Depannage
        }
    ]
})
const pinia = createPinia()
createApp(App)
    .use(router)
    .use(pinia)
    .mount('#app')
