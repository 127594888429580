import {defineStore} from "pinia";
import {CardProps, ServiceName} from "../types/props";
import {TestId} from "../types/anchor";

export const cardsMapByLink: Record<string, CardProps> = {
    [TestId.TOITURE_PAGELINK]: {
        title: ServiceName.AllTravauxToiture,
        description: 'Nous proposons des travaux de toiture pour tous types de toits, qu’il s’agisse de réparation, de rénovation ou de construction neuve.',
        imageSrc: '/image/toiture.jpg',
        imageAlt: 'travaux toiture image',
        link: TestId.TOITURE_PAGELINK,
        pageTestId: TestId.TOITURE_PAGE_CONTENT,
    },
    [TestId.SERVICE_DEPANNAGE_TOITURE_LINK]: {
        title: ServiceName.Depannage,
        description: 'Notre équipe utilise une flotte de camions nacelle sur véhicules légers (VL) pour réaliser des interventions de dépannage rapides et efficaces, offrant ainsi des solutions sûres et innovantes pour résoudre les problèmes les plus complexes.',
        imageSrc: 'https://www.hellopro.fr/images/produit-2/1/9/4/nacelle-articulee-24d-speed-h-h-camion-nacelle-8084491.jpg',
        imageAlt: 'dépannage image',
        link: TestId.SERVICE_DEPANNAGE_TOITURE_LINK,
        pageTestId: TestId.SERVICE_DEPANNAGE_TOITURE_CONTENT,
    },
    [TestId.SERVICE_PEINTURE_TOITURELINK]: {
        title: ServiceName.Peinture,
        description: 'Nous offrons des services de peinture intérieure et extérieure pour vos murs, plafonds et autres surfaces, utilisant des matériaux de haute qualité.',
        imageSrc: '/image/peinture.jpg',
        imageAlt: 'peinture interieure exterieure image',
        link: TestId.SERVICE_PEINTURE_TOITURELINK,
        pageTestId: TestId.SERVICE_PEINTURE_TOITURE_CONTENT,
    },
    [TestId.TOITURE_RAVELEMENT_FACADELINK]: {
        title: ServiceName.Facade,
        description: 'Nous réalisons des travaux de ravalement de façade pour restaurer l’aspect extérieur de vos bâtiments et les protéger des intempéries.',
        imageSrc: '/image/facade.jpg',
        imageAlt: 'ravalement facade image',
        link: TestId.TOITURE_RAVELEMENT_FACADELINK,
        pageTestId: TestId.TOITURE_RAVELEMENT_FACADE_CONTENT,
    },
    [TestId.TOITURE_NETTOYAGE_TOITURELINK]: {
        title: ServiceName.NettoyageTerrasse,
        description: 'Nous nettoyons vos toitures et terrasses pour éliminer les salissures, les mousses et les algues, et les rendre sécuritaires et agréables à utiliser.',
        imageSrc: '/image/nettoyage.jpg',
        imageAlt: 'nettoyage terrasse image',
        link: TestId.TOITURE_NETTOYAGE_TOITURELINK,
        pageTestId: TestId.TOITURE_NETTOYAGE_TOITURE_CONTENT,
    },
    [TestId.TOITURE_NETTOYAGE_DALLADES]: {
        title: ServiceName.NettoyageFacade,
        description: 'Nous nettoyons vos façades pour éliminer les salissures, les mousses et les algues, et les rendre sécuritaires et agréables à utiliser.',
        imageSrc: 'https://www.travaux.com/topic-cluster-heros/tc-22-1400w.png',
        imageAlt: 'nettoyage facade image',
        link: TestId.TOITURE_NETTOYAGE_DALLADES,
        pageTestId: TestId.TOITURE_NETTOYAGE_FACADE_CONTENT,
    },
    [TestId.SERVICE_MACONNERIE_TOITURE_LINK]: {
        title: ServiceName.Maconnerie,
        description: 'Nous proposons des services de maçonnerie pour la construction, la réparation et la rénovation de vos murs, fondations et autres structures.',
        imageSrc: '/image/macon.jpeg',
        imageAlt: 'maconnerie image',
        link: TestId.SERVICE_MACONNERIE_TOITURE_LINK,
        pageTestId: TestId.SERVICE_MACONNERIE_TOITURE_CONTENT,
    },
    [TestId.SERVICE_REMPLACEMENT_TUILES_TOITURE_LINK]: {
        title: ServiceName.RemplacementTuiles,
        description: 'Nous posons des carrelages pour vos sols, murs et autres surfaces, utilisant des matériaux de haute qualité.',
        imageSrc: '/image/toiture.jpg',
        imageAlt: 'pose de carrelage image',
        link: TestId.SERVICE_REMPLACEMENT_TUILES_TOITURE_LINK,
        pageTestId: TestId.SERVICE_REMPLACEMENT_TUILES_TOITURE_CONTENT,
    },
    [TestId.SERVICE_CREPI_TOITURE_LINK]: {
        title: ServiceName.Crepi,
        description: 'Nous proposons des services de crépi pour la protection et la décoration de vos murs et façades.',
        imageSrc: 'https://vss.astrocenter.fr/habitatpresto/pictures/29610483-adobestock-43454468.jpg',
        imageAlt: 'crépi image',
        link: TestId.SERVICE_CREPI_TOITURE_LINK,
        pageTestId: TestId.SERVICE_CREPI_TOITURE_CONTENT,
    },
};

interface GeneralStoreState {
    pageDataService: CardProps | null;
    cardPropsList: Record<TestId, CardProps>;
}

export const useGeneralStore = defineStore('general', {
    state: (): GeneralStoreState => ({
        pageDataService: null,
        cardPropsList: cardsMapByLink,
    }),
    getters: {
        getPageDataService(): CardProps | null {
            return this.pageDataService;
        },
        getCardPropsList(): Record<TestId, CardProps> {
            return this.cardPropsList;
        },
    },
    actions: {
        setPageDataService(data: CardProps) {
            this.pageDataService = data;
        }
    }
})