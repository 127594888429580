<script setup>

import CardTarif from "../components/CardTarifs.vue";
const cards = [
  {
    title: 'Nettoyage de Toiture - Préservez la Longévité de Votre Toiture',
    description: 'Nos experts utilisent des techniques avancées pour éliminer les débris, la mousse et les algues, garantissant une toiture propre et durable.',
    link: '#',
    price: '500'
  },
  {
    title: 'Rénovation de Toiture',
    description: 'Nos experts restaurent votre toiture pour lui redonner un aspect neuf et prolonger sa durée de vie.',
    link: '#',
    price: '500'
  },
  {
    title: 'Peinture de Toiture',
    description: 'Rafraîchissez et protégez votre toiture avec notre service de peinture spécialisé.',
    link: '#',
    price: '500'
  },
  {
    title: 'Isolation de Toiture',
    description: 'Nos experts restaurent votre toiture pour lui redonner un aspect neuf et prolonger sa durée de vie.',
    link: '#',
    price: '500'
  },
  {
    title: 'Réparation de toiture',
    description: 'Nos experts restaurent votre toiture pour lui redonner un aspect neuf et prolonger sa durée de vie.',
    link: '#',
    price: '500'
  },
  {
    title: 'Nettoyage de gouttieres',
    description: 'Nos experts restaurent votre toiture pour lui redonner un aspect neuf et prolonger sa durée de vie.',
    link: '#',
    price: '500'
  },
];
</script>

<template>
  <!-- <div id="app"></div> -->
  <!-- Hero Section -->
  <section class="bg-gray py-20">
    <div class="container mx-auto text-center">
      <h1 class="text-5xl font-bold text-primary mb-6">Découvrez nos tarifs compétitifs pour un nettoyage de toit de haute qualité</h1>
      <p class="text-lg text-gray-400 mb-8">Nos tarifs sont basés sur des années d'expérience et de savoir-faire dans le domaine du nettoyage de toit. Nous vous proposons des solutions personnalisées et compétitives pour répondre à vos besoins et à votre budget.</p>
      <h2 class="text-4xl font-bold text-secondary mb-12">Prix</h2>

    </div>
  </section>



  <section class="flex justify-center items-center min-h-screen bg-neutral flex flex-col justify-between">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
      <CardTarif
          v-for="card in cards"
          :key="card.title"
          :title="card.title"
          :description="card.description"
          :link="card.link"
          :price="card.price"
      />
    </div>
  </section>

</template>

<style scoped>

</style>
