<script setup>

</script>

<template>

  <!-- Footer Section -->
  <footer class="bg-gray-800 py-6 text-gray-400"
          data-testid="footer"
  >
    <div class="container mx-auto text-center">
      <p class="text-sm">&copy; 2024 NettoyagePro. Tous droits réservés.</p>
      <a class="hover:text-white" href="#">Politique de confidentialité</a>
    </div>
  </footer>
</template>

<style scoped>

</style>