<script setup lang="ts">
import Footer from "./layout/Footer.vue";
import Header from "./layout/Header.vue";
</script>

<template>
  <Header />
  <main data-testid="main"
        class="pt-16 "
  >
  <router-view />
  </main>
  <Footer />
</template>

<style scoped></style>